.abstract-card{
    background-color: white; 
}
@media (min-width: 768px) {
   .abstract-card{
    width: 100%;
   } 
   .abstract-card > div > div:nth-child(6){
    display: grid;
    grid-template-columns: 2;
    grid-template-rows: 2;
    justify-content: center;
   }
}

.categoryAndDifficultyHeading{
    text-align: center;
    margin-top: 30px;
}
@media (min-width: 768px) {
   .categoryAndDifficultyHeading{
    font-size: x-large;
   } 
}
.counter{
    display: grid;
    justify-content: center;
    font-size: x-large;
}
.question-no-header{
    display: flex;
    justify-content: space-around;
}
.question-no-header > button{
    width: 2em;
    height: 2em;
    border: none;
    margin: 1px;
    display: grid;
    place-content: center;
    font-size: large;
    color: white;
    border-radius: 50%;
}
@media (min-width: 768px) {
   .question-no-header > button{
        width: 3em;
        height: 3em;
   } 
}
.picture{
    border: 2px solid black;
    border-radius: 10px;
    grid-column-start: 1;
    margin:auto;
    width: 300px;
    height: 300px;
    background: contain;
    display: grid;
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column-end: 2;
    grid-row-start:1;
    grid-row-end: 4;
}
@media (min-width: 768px) {
   .picture{
    width: 400px;
    height: 400px;
   } 
}
.question{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-top: 20px;
    font-size: large;
}
@media (min-width: 768px) {
    .question{
        word-wrap: break-word;
        min-width: 500px;
        max-width: 500px;
    }
    .options{
        min-height: fit-content;
        max-height: 200px;
    }
}
.options{
    display: flex;
    flex-direction: column;
    margin: 20px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}
.options > label{
    margin-top: 10px;
    margin-bottom: 10px;
}
.options > label > input{
    scale: 110%;
}
.options > button{
    font-weight: bold;
    padding: 5px;
}
.next-finish-prev-btn{
    display: flex;
    padding: 30px;
}
.next-finish-prev-btn > button:first-child{
    width: 100%;
    padding: 5px;
}
.next-finish-prev-btn > button:last-child{
    width: 100%;
}
.next-finish-prev-btn > button:nth-child(2){
    width: 100%;
    font-weight: bold;
}
.result-page{
    display: grid;
}
.result-page > h3{
    font-size: x-large;
    margin: 3px;
    text-align: center;
}
.result-page > div{
    display: grid;
}
.OneQuesResult{
    display: grid;
}
.OneQuesResult > h2{
    font-size: larger;
}
.OneQuesResult > div{
    display: flex;
    flex-direction: column;
    font-size: large;
    justify-content: space-between;
}