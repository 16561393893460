*{
    margin: 0;
    font-family:  monospace;
}

.app{
    overflow-y: scroll;
    display: grid;
    min-height: 100vh;
    align-content: space-between;
}
.header{
    background-color: lightblue;
    width: 100vw;
    height: -webkit-max-content;
    height: max-content;
}
.header > header  a{
    text-align: center;
    text-decoration: none;
    color:black;
}
.header > header > p{
    text-align: center;
    font-size: 1.5em;
} 
.footer{
    background-color: lightblue;
    order: 3;
    width: 100vw;
}
.footer > h2{
    text-align: center;
}
.footer > h2 > a{
    text-decoration: none;
    color: black;
}
.homePage > h2{
    text-align: center;
}
.homePage > div{
    background-color: rgb(214, 228, 241);
    border-radius: 10px;
    order:2;
    margin: 14px;
    display:grid;
    height: -webkit-max-content;
    height: max-content;
    box-shadow: 4px 3px black;
}
@media (min-width: 768px) {
    .homePage > div{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 20px;
    }
}
/* These are for titles */
.homePage > div > div{
    border-radius: 5px;
    margin: 10px;
    box-shadow: 2px 2px 2px black;
}
@media (min-width: 768px) {
    .homePage > div > div{
        padding: 20px;
    }
}
/* Here too we are editing the categories */
.homePage > div > div > h1{
    font-size: x-large;
    text-align: center;
}
.homePage > div > div > div:first-child{
    display: grid;
}
/* Here we are editing the categories */
.homePage > div > div > div:first-child > h1{
    font-size: x-large;
    text-align: center;
}
/* Here we are editing the subcategories */
.homePage > div > div > div:first-child > div{
    display: grid;
    font-size: large;
}
.homePage > div > div > div:first-child
.homePage > div > div > div:nth-child(2){
    display: grid;
}
.homePage > div > div > div:nth-child(2) > h1{
    font-size: larger;
}
.homePage > div > div > div:nth-child(2) > div{
    display: flex;
}
.homePage > div > div > div:nth-child(2) > div > label{
    margin: 10px;
    font-size: large;
}
.homePage > div > div > div:nth-child(3) > button{
    padding: 2px;
}
.abstract-card{
    background-color: white; 
}
@media (min-width: 768px) {
   .abstract-card{
    width: 100%;
   } 
   .abstract-card > div > div:nth-child(6){
    display: grid;
    grid-template-columns: 2;
    grid-template-rows: 2;
    justify-content: center;
   }
}

.categoryAndDifficultyHeading{
    text-align: center;
    margin-top: 30px;
}
@media (min-width: 768px) {
   .categoryAndDifficultyHeading{
    font-size: x-large;
   } 
}
.counter{
    display: grid;
    justify-content: center;
    font-size: x-large;
}
.question-no-header{
    display: flex;
    justify-content: space-around;
}
.question-no-header > button{
    width: 2em;
    height: 2em;
    border: none;
    margin: 1px;
    display: grid;
    place-content: center;
    font-size: large;
    color: white;
    border-radius: 50%;
}
@media (min-width: 768px) {
   .question-no-header > button{
        width: 3em;
        height: 3em;
   } 
}
.picture{
    border: 2px solid black;
    border-radius: 10px;
    grid-column-start: 1;
    margin:auto;
    width: 300px;
    height: 300px;
    background: contain;
    display: grid;
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column-end: 2;
    grid-row-start:1;
    grid-row-end: 4;
}
@media (min-width: 768px) {
   .picture{
    width: 400px;
    height: 400px;
   } 
}
.question{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-top: 20px;
    font-size: large;
}
@media (min-width: 768px) {
    .question{
        word-wrap: break-word;
        min-width: 500px;
        max-width: 500px;
    }
    .options{
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content;
        max-height: 200px;
    }
}
.options{
    display: flex;
    flex-direction: column;
    margin: 20px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}
.options > label{
    margin-top: 10px;
    margin-bottom: 10px;
}
.options > label > input{
    scale: 110%;
}
.options > button{
    font-weight: bold;
    padding: 5px;
}
.next-finish-prev-btn{
    display: flex;
    padding: 30px;
}
.next-finish-prev-btn > button:first-child{
    width: 100%;
    padding: 5px;
}
.next-finish-prev-btn > button:last-child{
    width: 100%;
}
.next-finish-prev-btn > button:nth-child(2){
    width: 100%;
    font-weight: bold;
}
.result-page{
    display: grid;
}
.result-page > h3{
    font-size: x-large;
    margin: 3px;
    text-align: center;
}
.result-page > div{
    display: grid;
}
.OneQuesResult{
    display: grid;
}
.OneQuesResult > h2{
    font-size: larger;
}
.OneQuesResult > div{
    display: flex;
    flex-direction: column;
    font-size: large;
    justify-content: space-between;
}
.login-page{
    display:grid;
    background-color: lightblue;
    justify-content: center;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 4px 4px 4px black;
}
.login-page > div{
    background-color: slategray;
    border-radius: 10px;
    display:flex;
    flex-direction: column;
    margin-top:10px;
    margin-bottom: 80px;
    margin-left:auto;
    margin-right:auto;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
}
.login-page > div > h2{
    text-align: center;
    padding: 15px;
}
.login-page > div > form{
    padding-bottom: 5px;
    display: grid;
}
.login-page > div > form > label{
    display: grid;
}
.login-page > div > form > label > input{
    background-color: slategrey;
    border-radius: 2px;
    border-color: black;
    margin: auto;
    margin-bottom: 5px;
    border-style: double;
}

.login-page > div > button{
    padding: 6px;
}
.login-page > div > form > button{
    padding: 6px;
}
