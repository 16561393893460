.login-page{
    display:grid;
    background-color: lightblue;
    justify-content: center;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 4px 4px 4px black;
}
.login-page > div{
    background-color: slategray;
    border-radius: 10px;
    display:flex;
    flex-direction: column;
    margin-top:10px;
    margin-bottom: 80px;
    margin-left:auto;
    margin-right:auto;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
}
.login-page > div > h2{
    text-align: center;
    padding: 15px;
}
.login-page > div > form{
    padding-bottom: 5px;
    display: grid;
}
.login-page > div > form > label{
    display: grid;
}
.login-page > div > form > label > input{
    background-color: slategrey;
    border-radius: 2px;
    border-color: black;
    margin: auto;
    margin-bottom: 5px;
    border-style: double;
}

.login-page > div > button{
    padding: 6px;
}
.login-page > div > form > button{
    padding: 6px;
}